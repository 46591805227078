import { EnvironmentConfig } from '@nx-bundesliga/shared/config/environment-config';

export const environment: EnvironmentConfig = {
	build: 'int',
	webview: true,

	features: {
		LINK_AMP_EDITORIAL: false,
		LINK_AMP_TABLE: false,
		LINK_AMP_FIXTURES: false
	},

	gmaps: {
		key: 'AIzaSyAalXYcMQg1EvPsSUINxVOGsx20VvzWGH4'
	},

	captcha: {
		sitekey: 'FCMP7P1V5L528JJI'
	},

	analytics: {
		'gtag': 'GTM-P56772',
		'gtag-webview-accounts': 'GTM-N7Q4ZXG'
	},

	instana: {
		apikey: 'weZI7iSGSH2211gluPRmmw'
	},

	onetrust: {
		dataDomainScript: 'a54fb0dc-96f0-435b-81ad-486d922f0dba-test'
	},

	production: false,
	facebook: {
		appId: '397112304023421'
	},

	firebase: {
		apiKey: 'AIzaSyDPBrA4jejuIT_d5Lm6N6TXE-cqp5o2Hv8',
		authDomain: 'bundesliga-web-appr.firebaseapp.com',
		databaseURL: 'https://bundesliga-web-appr.europe-west1.firebasedatabase.app',
		projectId: 'bundesliga-web-official',
		storageBucket: 'bundesliga-web-official.appspot.com',
		messagingSenderId: '734520686470',
		appId: '1:734520686470:web:1b9eff516436e2be519e07'
	},

	endpoints: {
		config: {
			uri: 'https://wapp.bapi.bundesliga-appr.com/config/configNode.json',
			base: 'https://bundesliga-web-appr.europe-west1.firebasedatabase.app',
			uriFallbackFirebase: 'https://bundesliga-web-appr.europe-west1.firebasedatabase.app/config.json',
			uriFallbackStatic: 'https://www.bundesliga-appr.com/assets/config/firebase-config-fallback.json'
		},

		defaults: {
			key: 'A0nly28gtANgluf6-APPR',
			base: 'https://wapp.bapi.bundesliga-appr.com',
			header: 'x-api-key'
		},

		barfinder: {
			path: 'barfinder'
		},

		club: {
			path: 'club'
		},

		person: {
			path: 'person'
		},

		player: {
			path: 'player'
		},

		broadcaster: {
			path: 'broadcaster'
		},

		broadcasters: {
			path: 'broadcasters'
		},

		editorial: {
			path: 'editorial'
		},

		wiki: {
			path: 'editorial/wiki'
		},

		broadcasts: {
			path: 'broadcasts'
		},

		cdnLocale: {
			path: 'web-api/locale'
		},

		migration: {
			s3: 'https://migration-dfl-int.s3.amazonaws.com',
			cdn: 'https://s.bundesliga-appr.com'
		},

		contentSyndication: {
			url: 'https://widgets.bundesliga-appr.com',
			pathFixtures: '',
			pathTable: ''
		},

		adConfig: {
			de: {
				'DFL-COM-000001': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-de-dfl-com-000001.json',
				'DFL-COM-000002': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-de-dfl-com-000002.json'
			},
			en: {
				'DFL-COM-000001': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-en-dfl-com-000001.json',
				'DFL-COM-000002': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-en-dfl-com-000002.json'
			},
			es: {
				'DFL-COM-000001': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-es-dfl-com-000001.json',
				'DFL-COM-000002': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-es-dfl-com-000002.json'
			},
			fr: {
				'DFL-COM-000001': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-fr-dfl-com-000001.json',
				'DFL-COM-000002': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-fr-dfl-com-000002.json'
			},
			jp: {
				'DFL-COM-000001': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-jp-dfl-com-000001.json',
				'DFL-COM-000002': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-jp-dfl-com-000002.json'
			},
			ar: {
				'DFL-COM-000001': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-ar-dfl-com-000001.json',
				'DFL-COM-000002': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-ar-dfl-com-000002.json'
			},
			pt: {
				'DFL-COM-000001': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-pt-dfl-com-000001.json',
				'DFL-COM-000002': 'https://webview.bundesliga-appr.com/assets/config/adconfigs/adslot-config-pt-dfl-com-000002.json'
			}
		}
	},

	dfp: {
		'network': '66185244'
	},

	jw: {
		player: 's6QvxDMg',
		autoplay: true,
		poster: 'https://www.bundesliga.com/assets/logo/bundesliga.svg',
		playlist: {
			goals: {
				dach: 'qGuv3ENY',
				nondach: 'ZosXLSkY'
			},
			player: 'ReJQNyhH'
		},
		cdn: {
			base: 'https://cdn.jwplayer.com'
		}
	},

	system: {
		'applicationName': 'SEO.APP_NAME',
		'applicationUrl': 'https://www.bundesliga-appr.com'
	},

	okta: {
		restUrl: 'https://im.auth.bundesliga-appr.com',
		oktaRestUrl: 'https://bundesliga.oktapreview.com',
		clientId: '0oa1ekpxdjlDvWcXn0x7',
		iss: 'aus1dv1mcbWQVJbDe0x7',
		issuer: 'https://bundesliga.oktapreview.com/oauth2/aus1dv1mcbWQVJbDe0x7',
		idp: {
			google: '0oa1h9g50sUDDmD1W0x7',
			facebook: '0oa1hhufo2ItrPKiD0x7',
			apple: '0oa1h1so13et4dl5j0x7',
			x: ''
		}
	},

	seo: {
		'defaultPageTitle': 'SEO.DEFAULT_TITLE',
		'pageTitlePositioning': 0,
		'pageTitleSeparator': ' | ',
		'defaultMetaDescription': 'SEO.DEFAULT_META_DESCRIPTION'
	},

	i18n: {
		defaultLanguage: {
			code: 'de',
			name: 'German',
			localName: 'Deutsch',
			culture: 'de-DE',
			ietf: 'de_DE'
		},
		availableLanguages: [
			{
				code: 'en',
				name: 'English',
				localName: 'English',
				culture: 'en-EN',
				ietf: 'en_US'
			},
			{
				code: 'de',
				name: 'German',
				localName: 'Deutsch',
				culture: 'de-DE',
				ietf: 'de_DE'
			},
			{
				code: 'es',
				name: 'Spanish',
				localName: 'Español',
				culture: 'es-ES',
				ietf: 'es_ES'
			},
			{
				code: 'jp',
				name: 'Japanese',
				localName: '日本語',
				culture: 'ja-JP',
				ietf: 'ja_JP'
			},
			{
				code: 'fr',
				name: 'French',
				localName: 'Français',
				culture: 'fr-FR',
				ietf: 'fr_FR'
			},
			{
				code: 'ar',
				name: 'Arabic',
				localName: ' العربية',
				culture: 'ar-AE',
				ietf: 'ar_AE'
			},
			{
				code: 'pt',
				name: 'Portuguese',
				localName: 'Português',
				culture: 'pt-BR',
				ietf: 'pt_BR'
			}
		],
		language: {
			de: {
				enabled: true,
				competitions: {
					'bundesliga': true,
					'2bundesliga': true,
					'euro': true
				}
			},
			en: {
				enabled: true,
				competitions: {
					'bundesliga': true,
					'2bundesliga': true,
					'euro': true
				}
			},
			es: {
				enabled: true,
				competitions: {
					'bundesliga': true,
					'2bundesliga': true,
					'euro': false
				}
			},
			jp: {
				enabled: true,
				competitions: {
					'bundesliga': true,
					'2bundesliga': true,
					'euro': false
				}
			},
			fr: {
				enabled: true,
				competitions: {
					'bundesliga': true,
					'2bundesliga': true,
					'euro': false
				}
			},
			ar: {
				enabled: true,
				competitions: {
					'bundesliga': true,
					'2bundesliga': true,
					'euro': false
				}
			},
			pt: {
				enabled: true,
				competitions: {
					'bundesliga': true,
					'2bundesliga': true,
					'euro': false
				}
			}
		}
	},

	navigation: {
		'de': {
			'bundesliga': [
				{ 'label': 'Home', 'url': '' },
				// {'label': 'News', 'url': 'route-news'},
				{ 'label': 'Spielplan', 'url': 'route-matchday' },
				{ 'label': 'Tabelle', 'url': 'route-table' },
				{ 'label': 'Clubs', 'url': 'route-clubs' },
				{ 'label': 'Liveticker', 'url': 'route-liveticker-conference' },
				{ 'label': 'History', 'url': 'de/route-history', absolute: true },
				{ 'label': 'Videos', 'url': 'route-videos' },
				{ 'label': 'Tickets', 'url': 'route-tickets' },
				{ 'label': 'Statistiken', 'url': 'route-stats' },
				{ 'label': 'Tabellenrechner', 'url': 'route-table-predictor' },
				{ 'label': 'Awards', 'url': 'route-awards' },
				{ 'label': 'Spieler', 'url': 'route-player' },
				{ 'label': 'FAQ', 'url': '/de/route-wiki', absolute: true }
			],
			'2bundesliga': [
				{ 'label': 'Home', 'url': '' },
				// {'label': 'News', 'url': 'route-news'},
				{ 'label': 'Spielplan', 'url': 'route-matchday' },
				{ 'label': 'Tabelle', 'url': 'route-table' },
				{ 'label': 'Clubs', 'url': 'route-clubs' },
				{ 'label': 'Liveticker', 'url': 'route-liveticker-conference' },
				{ 'label': 'Videos', 'url': 'route-videos' },
				{ 'label': 'Tickets', 'url': 'route-tickets' },
				{ 'label': 'Statistiken', 'url': 'route-stats' },
				{ 'label': 'Tabellenrechner', 'url': 'route-table-predictor' },
				{ 'label': 'Spieler', 'url': 'route-player' },
				{ 'label': 'FAQ', 'url': '/de/route-wiki', absolute: true }
			],
			'euro': [
				{ 'label': 'Home', 'url': '' },
				{ 'label': 'Spielplan', 'url': 'route-matchday' },
				{ 'label': 'Tabelle', 'url': 'route-table' }
			]
		},
		'en': {
			'bundesliga': [
				{ 'label': 'Home', 'url': '' },
				// {'label': 'News & Features', 'url': 'route-news'},
				{ 'label': 'Table', 'url': 'route-table' },
				{ 'label': 'Clubs', 'url': 'route-clubs' },
				{ 'label': 'Fixtures & Results', 'url': 'route-matchday' },
				{ 'label': 'Live', 'url': 'route-liveticker-conference' },
				{ 'label': 'History', 'url': 'en/route-history', absolute: true },
				{ 'label': 'Videos', 'url': 'route-videos' },
				//{'label': 'Legends', 'url': 'route-legends'},
				{
					'label': 'Legends',
					'url': '/en/bundesliga/news/legends-network-meet-the-players-matthaus-klinsmann-pizarro-okocha-17040',
					absolute: true
				},
				{ 'label': 'Stats', 'url': 'route-stats' },
				{ 'label': 'Table predictor', 'url': 'route-table-predictor' },
				{ 'label': 'Awards', 'url': 'route-awards' },
				{ 'label': 'FAQ', 'url': '/en/route-wiki', absolute: true },
				{ 'label': 'Players', 'url': 'route-player' },
				{ 'label': 'US Barfinder', 'url': 'barfinder' }
			],
			'2bundesliga': [
				{ 'label': 'Home', 'url': '' },
				// {'label': 'News & Features', 'url': 'route-news'},
				{ 'label': 'Table', 'url': 'route-table' },
				{ 'label': 'Clubs', 'url': 'route-clubs' },
				{ 'label': 'Fixtures & Results', 'url': 'route-matchday' },
				{ 'label': 'Live', 'url': 'route-liveticker-conference' },
				{ 'label': 'Videos', 'url': 'route-videos' },
				{ 'label': 'Stats', 'url': 'route-stats' },
				{ 'label': 'Table predictor', 'url': 'route-table-predictor' },
				{ 'label': 'FAQ', 'url': '/en/route-wiki', absolute: true },
				{ 'label': 'Players', 'url': 'route-player' }
			],
			'euro': [
				{ 'label': 'Home', 'url': '' },
				{ 'label': 'Table', 'url': 'route-table' },
				{ 'label': 'Fixtures & Results', 'url': 'route-matchday' },
				{ 'label': 'City guides', 'url': 'route-city-guides' }
			]
		},
		'es': {
			'bundesliga': [
				{ 'label': 'Portada', 'url': '' },
				/* {'label': 'Noticias', 'url': 'route-news'}, */
				{ 'label': 'Partidos', 'url': 'route-matchday' },
				{ 'label': 'Clasificación', 'url': 'route-table' },
				{ 'label': 'Clubes', 'url': 'route-clubs' },
				{ 'label': 'En Vivo', 'url': 'route-liveticker-conference' },
				//{'label': 'Videos', 'url': 'route-videos'},
				//{'label': 'Leyendas', 'url': 'route-legends'},
				{ 'label': 'Jugadores', 'url': 'route-player' },
				// {'label': 'Especiales', 'url': 'especiales'}
				{ 'label': 'Estadísticas', 'url': 'route-stats' },
				{ 'label': 'Golazos Latinos', 'url': 'route-golazos' }
			],
			'2bundesliga': [
				{ 'label': 'Portada', 'url': '' },
				/* {'label': 'Noticias', 'url': 'route-news'}, */
				{ 'label': 'Partidos', 'url': 'route-matchday' },
				{ 'label': 'Clasificación', 'url': 'route-table' },
				{ 'label': 'Clubes', 'url': 'route-clubs' },
				{ 'label': 'En Vivo', 'url': 'route-liveticker-conference' },
				//{'label': 'Videos', 'url': 'route-videos'},
				//{'label': 'Leyendas', 'url': 'route-legends'},
				{ 'label': 'Jugadores', 'url': 'route-player' },
				// {'label': 'Especiales', 'url': 'especiales'}
				{ 'label': 'Estadísticas', 'url': 'route-stats' }
			]
		},
		'fr': {
			'bundesliga': [
				{ 'label': 'Début', 'url': '' },
				{ 'label': 'Journée', 'url': 'route-matchday' },
				{ 'label': 'Classement', 'url': 'route-table' },
				{ 'label': 'Clubs', 'url': 'route-clubs' },
				{ 'label': 'Liveticker', 'url': 'route-liveticker-conference' },
				{ 'label': 'Joueurs', 'url': 'route-player' },
				{ 'label': 'Stats', 'url': 'route-stats' }
			],
			'2bundesliga': [
				{ 'label': 'Début', 'url': '' },
				{ 'label': 'Journée', 'url': 'route-matchday' },
				{ 'label': 'Classement', 'url': 'route-table' },
				{ 'label': 'Clubs', 'url': 'route-clubs' },
				{ 'label': 'Liveticker', 'url': 'route-liveticker-conference' },
				{ 'label': 'Joueurs', 'url': 'route-player' },
				{ 'label': 'Stats', 'url': 'route-stats' }
			]
		},
		'jp': {
			'bundesliga': [
				{ 'label': ' ホーム ', 'url': '' },
				/* {'label': ' ニュース ', 'url': 'route-news'},*/
				{ 'label': ' 試合結果・日程 ', 'url': 'route-matchday' },
				{ 'label': ' 順位 ', 'url': 'route-table' },
				{ 'label': ' クラブ ', 'url': 'route-clubs' },
				{ 'label': 'Live', 'url': 'route-liveticker-conference' },
				//{'label': ' 動画 ', 'url': 'route-videos'},
				{ 'label': 'データ', 'url': 'route-stats' },
				{ 'label': '選手', 'url': 'route-player' }
			],
			'2bundesliga': [
				{ 'label': ' ホーム ', 'url': '' },
				/* {'label': ' ニュース ', 'url': 'route-news'},*/
				{ 'label': ' 試合結果・日程 ', 'url': 'route-matchday' },
				{ 'label': ' 順位 ', 'url': 'route-table' },
				{ 'label': ' クラブ ', 'url': 'route-clubs' },
				{ 'label': 'Live', 'url': 'route-liveticker-conference' },
				//{'label': ' 動画 ', 'url': 'route-videos'},
				{ 'label': 'データ', 'url': 'route-stats' },
				{ 'label': '選手', 'url': 'route-player' }
			]
		},
		'ar': {
			'bundesliga': [
				{ 'label': 'الرئيسية', 'url': '' },
				{ 'label': 'المباريات والنتائج', 'url': 'route-matchday' },
				{ 'label': 'جدول الترتيب', 'url': 'route-table' },
				{ 'label': 'الأندية', 'url': 'route-clubs' },
				{ 'label': 'مباشر', 'url': 'route-liveticker-conference' },
				{ 'label': 'اللاعبون', 'url': 'route-player' },
				{ 'label': 'الإحصائيات', 'url': 'route-stats' }
			],
			'2bundesliga': [
				{ 'label': 'الرئيسية', 'url': '' },
				{ 'label': 'المباريات والنتائج', 'url': 'route-matchday' },
				{ 'label': 'جدول الترتيب', 'url': 'route-table' },
				{ 'label': 'الأندية', 'url': 'route-clubs' },
				{ 'label': 'مباشر', 'url': 'route-liveticker-conference' },
				{ 'label': 'اللاعبون', 'url': 'route-player' },
				{ 'label': 'الإحصائيات', 'url': 'route-stats' }
			]
		},
		'pt': {
			'bundesliga': [
				{ 'label': 'Página Inicial', 'url': '' },
				{ 'label': 'Programação e Resultados', 'url': 'route-matchday' },
				{ 'label': 'Tabela', 'url': 'route-table' },
				{ 'label': 'Clubes', 'url': 'route-clubs' },
				{ 'label': 'Ao vivo', 'url': 'route-liveticker-conference' },
				{ 'label': 'Jogadores', 'url': 'route-player' },
				{ 'label': 'Estatísticas', 'url': 'route-stats' }
			],
			'2bundesliga': [
				{ 'label': 'Página Inicial', 'url': '' },
				{ 'label': 'Programação e Resultados', 'url': 'route-matchday' },
				{ 'label': 'Tabela', 'url': 'route-table' },
				{ 'label': 'Clubes', 'url': 'route-clubs' },
				{ 'label': 'Ao vivo', 'url': 'route-liveticker-conference' },
				{ 'label': 'Jogadores', 'url': 'route-player' },
				{ 'label': 'Estatísticas', 'url': 'route-stats' }
			]
		}
	},

	metanavigation: {
		'de': [
			{
				'label': 'Broadcaster',
				'url': 'bundesliga/route-info/route-broadcasters',
				'external': false
			},
			{
				'label': 'Bundesliga App',
				'url': 'https://buli.page.link/qah-de',
				'external': true
			},
			{
				'label': 'Fantasy Manager',
				url: 'https://fantasy.bundesliga.de',
				external: true
			},
			{
				'label': '#BundesligaWIRKT',
				url: 'https://bundesliga-wirkt.dfl.de/',
				external: true
			},
			{ 'label': 'DFL', 'url': 'https://www.dfl.de/de/home/', external: true }
		],
		'en': [
			{
				'label': 'Broadcasters',
				'url': 'bundesliga/route-info/route-broadcasters',
				'external': false
			},
			{
				'label': 'Bundesliga App',
				'url': 'https://buli.page.link/qah',
				'external': true
			},
			{
				'label': 'Fantasy Manager',
				'url': 'https://fantasy.bundesliga.com',
				'external': true
			},
			{ 'label': 'DFL', 'url': 'https://www.dfl.de/en/home/', external: true }
		],
		'es': [
			{
				'label': 'Canales',
				'url': 'bundesliga/route-info/route-broadcasters',
				'external': false
			},
			{
				'label': 'Bundesliga App',
				'url': 'https://buli.page.link/qah',
				'external': true
			},
			{
				'label': 'Fantasy Manager',
				'url': 'https://fantasy.bundesliga.com',
				'external': true
			}
		],
		'fr': [
			{
				'label': 'Diffuseurs',
				'url': 'bundesliga/route-info/route-broadcasters',
				'external': false
			},
			{
				'label': 'Bundesliga App',
				'url': 'https://buli.page.link/qah',
				'external': true
			},
			{
				'label': 'Fantasy Manager',
				'url': 'https://fantasy.bundesliga.com',
				'external': true
			}
		],
		'jp': [
			{
				'label': '放送局',
				'url': 'bundesliga/route-info/route-broadcasters',
				'external': false
			},
			{
				'label': 'ブンデスリーガ公式アプリ',
				'url': 'https://www.bundesliga.com/en/bundesliga/app',
				'external': true
			},
			{
				'label': 'ファンタジー・マネジャー',
				'url': 'https://fantasy.bundesliga.com',
				'external': true
			}
		],
		'ar': [
			{
				'label': 'القنوات الناقلة',
				'url': 'bundesliga/route-info/route-broadcasters',
				'external': false
			},
			{
				'label': 'Bundesliga App',
				'url': 'https://buli.page.link/qah',
				'external': true
			},
			{
				'label': 'Fantasy Manager',
				'url': 'https://fantasy.bundesliga.com',
				'external': true
			},
			{ 'label': 'رابطة الدوري الألماني لكرة القدم', 'url': 'https://www.dfl.de/en/home/', external: true }
		],
		'pt': [
			{
				'label': 'Emissoras',
				'url': 'bundesliga/route-info/route-broadcasters',
				'external': false
			},
			{
				'label': 'Aplicativo da Bundesliga',
				'url': 'https://buli.page.link/qah',
				'external': true
			},
			{
				'label': 'Fantasy Manager',
				'url': 'https://fantasy.bundesliga.com',
				'external': true
			},
			{ 'label': 'DFL', 'url': 'https://www.dfl.de/en/home/', external: true }
		]
	},

	footerNavigation: {
		'de': {
			'bundesliga': [
				{
					'label': 'Rechtliche Hinweise',
					'url': 'route-info/rechtliche-hinweise'
				},
				{
					'label': 'Cookie Einstellungen',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{ 'label': 'Datenschutz', 'url': 'route-info/route-privacy' },
				{ 'label': 'Nutzungsbedingungen', 'url': 'route-info/route-tos' },
				{ 'label': 'Broadcaster', 'url': 'route-info/route-broadcasters' },
				{ 'label': 'Kontakt', 'url': 'route-info/kontakt' },
				{
					'label': 'Jobs',
					'url': 'https://www.dfl.de/de/jobs/',
					external: true
				},
				{ 'label': 'Impressum', 'url': 'route-info/impressum' },
				{ 'label': 'Partner', 'url': 'route-info/partner' },
				{ 'label': 'Spieler', 'url': 'route-player' },
				{ 'label': 'Liveticker', 'url': 'route-liveticker-conference' }
			],
			'2bundesliga': [
				{
					'label': 'Rechtliche Hinweise',
					'url': 'route-info/rechtliche-hinweise'
				},
				{
					'label': 'Cookie Einstellungen',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{ 'label': 'Datenschutz', 'url': 'route-info/route-privacy' },
				{
					'label': 'Nutzungsbedingungen',
					'url': 'route-info/allgemeine-nutzungsbedingungen'
				},
				{ 'label': 'Kontakt', 'url': 'route-info/kontakt' },
				{
					'label': 'Jobs',
					'url': 'https://www.dfl.de/de/jobs/',
					external: true
				},
				{ 'label': 'Impressum', 'url': 'route-info/impressum' },
				{ 'label': 'Partner', 'url': 'route-info/partner' },
				{ 'label': 'Spieler', 'url': 'route-player' }
			],
			'euro': [
				{
					'label': 'Rechtliche Hinweise',
					'url': 'route-info/rechtliche-hinweise'
				},
				{
					'label': 'Cookie Einstellungen',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{ 'label': 'Datenschutz', 'url': 'route-info/route-privacy' },
				{ 'label': 'Nutzungsbedingungen', 'url': 'route-info/route-tos' },
				{ 'label': 'Broadcaster', 'url': 'route-info/route-broadcasters' },
				{ 'label': 'Kontakt', 'url': 'route-info/kontakt' },
				{
					'label': 'Jobs',
					'url': 'https://www.dfl.de/de/jobs/',
					external: true
				},
				{ 'label': 'Impressum', 'url': 'route-info/impressum' },
				{ 'label': 'Partner', 'url': 'route-info/partner' },
				{ 'label': 'Spieler', 'url': 'route-player' },
				{ 'label': 'Liveticker', 'url': 'route-liveticker-conference' }
			]
		},
		'en': {
			'bundesliga': [
				{
					'label': 'Advertising',
					'url': 'https://dfl.de/en/home/',
					external: true
				},
				{ 'label': 'Legal Notices', 'url': 'route-info/legal-notices' },
				{
					'label': 'Cookie settings',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{ 'label': 'Privacy Statement', 'url': 'route-info/route-privacy' },
				{ 'label': 'Terms of Use', 'url': 'route-info/route-tos' },
				{ 'label': 'Broadcasters', 'url': 'route-info/broadcasters' },
				{
					'label': 'Jobs',
					'url': 'https://www.dfl.de/en/jobs/',
					external: true
				},
				{ 'label': 'Imprint', 'url': 'route-info/imprint' },
				{ 'label': 'Contact', 'url': 'route-info/contact' },
				{ 'label': 'Partner', 'url': 'route-info/partner' },
				{ 'label': 'Player', 'url': 'route-player' }
			],
			'2bundesliga': [
				{
					'label': 'Advertising',
					'url': 'https://dfl.de/en/home/',
					external: true
				},
				{ 'label': 'Legal Notices', 'url': 'route-info/legal-notices' },
				{
					'label': 'Cookie settings',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{ 'label': 'Privacy Statement', 'url': 'route-info/route-privacy' },
				{ 'label': 'Terms of Use', 'url': 'route-info/route-tos' },
				{
					'label': 'Jobs',
					'url': 'https://www.dfl.de/en/jobs/',
					external: true
				},
				{ 'label': 'Imprint', 'url': 'route-info/imprint' },
				{ 'label': 'Contact', 'url': 'route-info/contact' },
				{ 'label': 'Partner', 'url': 'route-info/partner' },
				{ 'label': 'Player', 'url': 'route-player' }
			],
			'euro': [
				{
					'label': 'Advertising',
					'url': 'https://dfl.de/en/home/',
					external: true
				},
				{ 'label': 'Legal Notices', 'url': 'route-info/legal-notices' },
				{
					'label': 'Cookie settings',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{ 'label': 'Privacy Statement', 'url': 'route-info/route-privacy' },
				{ 'label': 'Terms of Use', 'url': 'route-info/route-tos' },
				{ 'label': 'Broadcasters', 'url': 'route-info/broadcasters' },
				{
					'label': 'Jobs',
					'url': 'https://www.dfl.de/en/jobs/',
					external: true
				},
				{ 'label': 'Imprint', 'url': 'route-info/imprint' },
				{ 'label': 'Contact', 'url': 'route-info/contact' },
				{ 'label': 'Partner', 'url': 'route-info/partner' },
				{ 'label': 'Player', 'url': 'route-player' }
			]
		},
		'es': {
			'bundesliga': [
				{
					'label': 'Publicidad',
					'url': 'https://dfl.de/en/home/',
					external: true
				},
				{ 'label': 'Aviso legal', 'url': 'route-info/route-tos' },
				{
					'label': 'Configuración de cookies',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{
					'label': 'Declaracion de privacidad',
					'url': 'route-info/route-privacy'
				},
				{ 'label': 'Canales', 'url': 'route-info/route-broadcasters' },
				{
					'label': 'Trabajos',
					'url': 'https://www.dfl.de/en/jobs/',
					external: true
				},
				{ 'label': 'Jugadores', 'url': 'route-player' },
				{ 'label': 'Condiciones de uso', 'url': 'route-info/condiciones-de-uso' },
				{ 'label': 'Sello Editorial', 'url': 'route-info/sello-editorial' },
				{ 'label': 'Contacto', 'url': 'route-info/contacto' }
			],
			'2bundesliga': [
				{
					'label': 'Publicidad',
					'url': 'https://dfl.de/en/home/',
					external: true
				},
				{ 'label': 'Aviso legal', 'url': 'route-info/route-tos' },
				{
					'label': 'Configuración de cookies',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{
					'label': 'Declaracion de privacidad',
					'url': 'route-info/route-privacy'
				},
				{ 'label': 'Canales', 'url': 'route-info/route-broadcasters' },
				{
					'label': 'Trabajos',
					'url': 'https://www.dfl.de/en/jobs/',
					external: true
				},
				{ 'label': 'Jugadores', 'url': 'route-player' },
				{ 'label': 'Condiciones de uso', 'url': 'route-info/condiciones-de-uso' },
				{ 'label': 'Sello Editorial', 'url': 'route-info/sello-editorial' },
				{ 'label': 'Contacto', 'url': 'route-info/contacto' }
			]
		},
		'fr': {
			'bundesliga': [
				{
					'label': 'La publicité',
					'url': 'https://dfl.de/en/home/',
					external: true
				},
				{ 'label': 'Conditions d’utilisation des services', 'url': 'route-info/route-tos' },
				{ 'label': 'Mentions Légales', 'url': 'route-info/route-legal' },
				{
					'label': 'Paramètres des cookies',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{
					'label': 'Déclaration de confidentialité',
					'url': 'route-info/route-privacy'
				},
				{ 'label': 'Diffuseurs', 'url': 'route-info/route-broadcasters' },
				{
					'label': 'Travaux',
					'url': 'https://www.dfl.de/en/jobs/',
					external: true
				},
				{ 'label': 'Contact', 'url': 'route-info/contact' },
				{ 'label': 'Impression', 'url': 'route-info/route-imprint' },
				{ 'label': 'Joueurs', 'url': 'route-player' }
			],
			'2bundesliga': [
				{
					'label': 'La publicité',
					'url': 'https://dfl.de/en/home/',
					external: true
				},
				{ 'label': 'Conditions d’utilisation des services', 'url': 'route-info/route-tos' },
				{ 'label': 'Mentions Légales', 'url': 'route-info/route-legal' },
				{
					'label': 'Paramètres des cookies',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{
					'label': 'Déclaration de confidentialité',
					'url': 'route-info/route-privacy'
				},
				{ 'label': 'Diffuseurs', 'url': 'route-info/route-broadcasters' },
				{
					'label': 'Travaux',
					'url': 'https://www.dfl.de/en/jobs/',
					external: true
				},
				{ 'label': 'Contact', 'url': 'route-info/contact' },
				{ 'label': 'Impression', 'url': 'route-info/route-imprint' },
				{ 'label': 'Joueurs', 'url': 'route-player' }
			]
		},
		'jp': {
			'bundesliga': [
				/* {'label': '概要', 'url': 'https://dfl.de/en/home/', external: true},*/
				{
					'label': 'プライバシー・ポリシー',
					'url': 'route-info/route-privacy'
				},
				{
					'label': 'クッキー設定',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				/* {'label': 'よくあるご質問', 'url': 'route-info/about-questions'}, */
				{ 'label': '利用条件', 'url': 'route-info/route-tos' },
				{ 'label': '放送局', 'url': 'route-info/broadcasters' },
				{
					'label': '求人',
					'url': 'https://www.dfl.de/en/jobs/',
					external: true
				},
				{ 'label': '選手', 'url': 'route-player' },
				/* {'label': 'Contact', 'url': 'route-info/contact'} */
				{ 'label': '当サイトについて', 'url': 'route-info/imprint' }
			],
			'2bundesliga': [
				/* {'label': '概要', 'url': 'https://dfl.de/en/home/', external: true},*/
				{
					'label': 'プライバシー・ポリシー',
					'url': 'route-info/route-privacy'
				},
				{
					'label': 'クッキー設定',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				/* {'label': 'よくあるご質問', 'url': 'route-info/about-questions'}, */
				{ 'label': '利用条件', 'url': 'route-info/route-tos' },
				{ 'label': '放送局', 'url': 'route-info/broadcasters' },
				{
					'label': '求人',
					'url': 'https://www.dfl.de/en/jobs/',
					external: true
				},
				{ 'label': '選手', 'url': 'route-player' },
				/* {'label': 'Contact', 'url': 'route-info/contact'} */
				{ 'label': '当サイトについて', 'url': 'route-info/imprint' }
			]
		},
		'ar': {
			'bundesliga': [
				{
					'label': 'الإعلانات',
					'url': 'https://dfl.de/en/home/',
					external: true
				},
				{ 'label': 'الإخطارات القانونية', 'url': 'route-info/legal-notices' },
				{
					'label': 'إعدادات ملفات تعريف الارتباط',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{ 'label': 'بيان الخصوصية', 'url': 'route-info/route-privacy' },
				{ 'label': 'شروط الاستخدام', 'url': 'route-info/route-tos' },
				{ 'label': 'القنوات الناقلة', 'url': 'route-info/broadcasters' },
				{
					'label': 'الوظائف',
					'url': 'https://www.dfl.de/en/jobs/',
					external: true
				},
				{ 'label': 'جهة النشر', 'url': 'route-info/imprint' },
				{ 'label': 'تواصل معنا', 'url': 'route-info/contact' },
				{ 'label': 'اللاعبون', 'url': 'route-player' }
			],
			'2bundesliga': [
				{
					'label': 'الإعلانات',
					'url': 'https://dfl.de/en/home/',
					external: true
				},
				{ 'label': 'الإخطارات القانونية', 'url': 'route-info/legal-notices' },
				{
					'label': 'إعدادات ملفات تعريف الارتباط',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{ 'label': 'بيان الخصوصية', 'url': 'route-info/route-privacy' },
				{ 'label': 'شروط الاستخدام', 'url': 'route-info/route-tos' },
				{
					'label': 'الوظائف',
					'url': 'https://www.dfl.de/en/jobs/',
					external: true
				},
				{ 'label': 'جهة النشر', 'url': 'route-info/imprint' },
				{ 'label': 'تواصل معنا', 'url': 'route-info/contact' },
				{ 'label': 'اللاعبون', 'url': 'route-player' }
			]
		},
		'pt': {
			'bundesliga': [
				{
					'label': 'Publicidade',
					'url': 'https://dfl.de/en/home/',
					external: true
				},
				{ 'label': 'Avisos legais', 'url': 'route-info/avisos-legais' },
				{
					'label': 'Configurações de Cookies',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{ 'label': 'Aviso de privacidade', 'url': 'route-info/route-privacy' },
				{ 'label': 'Termos de uso', 'url': 'route-info/route-tos' },
				{ 'label': 'Emissoras', 'url': 'route-info/route-broadcasters' },
				{
					'label': 'Trabalhe conosco',
					'url': 'https://www.dfl.de/en/jobs/',
					external: true
				},
				{ 'label': 'Marca', 'url': 'route-info/marca' },
				{ 'label': 'Contato', 'url': 'route-info/contato' },
				{ 'label': 'Jogadores', 'url': 'route-player' }
			],
			'2bundesliga': [
				{
					'label': 'Publicidade',
					'url': 'https://dfl.de/en/home/',
					external: true
				},
				{ 'label': 'Avisos legais', 'url': 'route-info/avisos-legais' },
				{
					'label': 'Configurações de Cookies',
					'external': true,
					'classNames': 'ot-sdk-show-settings'
				},
				{ 'label': 'Aviso de privacidade', 'url': 'route-info/route-privacy' },
				{ 'label': 'Termos de uso', 'url': 'route-info/route-tos' },
				{
					'label': 'Trabalhe conosco',
					'url': 'https://www.dfl.de/en/jobs/',
					external: true
				},
				{ 'label': 'Marca', 'url': 'route-info/marca' },
				{ 'label': 'Contato', 'url': 'route-info/contato' },
				{ 'label': 'Jogadores', 'url': 'route-player' }
			]
		}
	},

	footerPartnerlogo: {
		'de': [
			{
				'label': 'aws',
				'url': '/de/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 48,
				'height': 30
			},
			{
				'label': 'derbystar',
				'url': 'https://derbystar.de/',
				ext: 'svg',
				external: true,
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_footer',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'sky',
				'url': 'https://redirect.bundesliga.com/sky',
				external: true,
				ext: 'svg',
				'width': 56,
				'height': 34
			},
			{
				'label': 'dazn',
				'url': 'https://www.dazn.com/de-DE/welcome',
				external: true,
				ext: 'svg',
				'width': 35,
				'height': 34
			},
			{
				'label': 'tipico',
				'url': 'https://redirect.bundesliga.com/tipico_footer',
				external: true,
				ext: 'svg',
				'width': 86,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'http://www.easports.com/de/fifa',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': 'https://topps.com/',
				external: true,
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		],
		'en': [
			{
				'label': 'aws',
				'url': '/en/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 48,
				'height': 30
			},
			{
				'label': 'derbystar',
				'url': 'https://redirect.bundesliga.com/derbystar',
				external: true,
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_footer_int',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'https://www.easports.com/uk/fifa?change_culture=1',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': 'https://uk.topps.com/',
				external: true,
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		],
		'fr': [
			{
				'label': 'aws',
				'url': '/en/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 48,
				'height': 30
			},
			{
				'label': 'derbystar',
				'url': 'https://redirect.bundesliga.com/derbystar',
				external: true,
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_footer_int',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'https://www.easports.com/uk/fifa?change_culture=1',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': 'https://uk.topps.com/index.php/sport/bundesliga-uk.html',
				external: true,
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		],
		'jp': [
			{
				'label': 'aws',
				'url': '/en/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 48,
				'height': 30
			},
			{
				'label': 'derbystar',
				'url': 'https://redirect.bundesliga.com/derbystar',
				external: true,
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_footer_int',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'https://www.easports.com/uk/fifa?change_culture=1',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': 'https://www.topps.com/cards-collectibles.html?property=11221&p=1',
				external: true,
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		],
		'es': [
			{
				'label': 'aws',
				'url': '/en/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 48,
				'height': 30
			},
			{
				'label': 'derbystar',
				'url': 'https://redirect.bundesliga.com/derbystar',
				external: true,
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_footer_int',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'https://www.easports.com/uk/fifa?change_culture=1',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': 'https://es.topps.com/football/bundesliga-es.html',
				external: true,
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		],
		'ar': [
			{
				'label': 'aws',
				'url': '/en/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 48,
				'height': 30
			},
			{
				'label': 'derbystar',
				'url': 'https://redirect.bundesliga.com/derbystar',
				external: true,
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_footer_int',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'https://www.easports.com/uk/fifa?change_culture=1',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': 'https://uk.topps.com/index.php/sport/bundesliga-uk.html',
				external: true,
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		],
		'pt': [
			{
				'label': 'aws',
				'url': '/en/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 48,
				'height': 30
			},
			{
				'label': 'derbystar',
				'url': 'https://redirect.bundesliga.com/derbystar',
				external: true,
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_footer_int',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'https://www.easports.com/uk/fifa?change_culture=1',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': 'https://uk.topps.com/index.php/sport/bundesliga-uk.html',
				external: true,
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		]
	},

	partnerBar: {
		'de': [
			{
				'label': 'aws',
				'url': '/de/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 52,
				'height': 32
			},
			{
				'label': 'derbystar',
				'url': 'https://derbystar.de',
				ext: 'svg',
				external: true,
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_bar',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'sky',
				'url': 'https://redirect.bundesliga.com/sky',
				external: true,
				ext: 'svg',
				'width': 56,
				'height': 34
			},
			{
				'label': 'dazn',
				'url': 'https://www.dazn.com/de-DE/welcome',
				external: true,
				ext: 'svg',
				'width': 35,
				'height': 34
			},
			{
				'label': 'tipico',
				'url': 'https://redirect.bundesliga.com/tipico_bar',
				external: true,
				ext: 'svg',
				'width': 86,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'http://www.easports.com/de/fifa',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': '/de/bundesliga/route-info/partner/topps',
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		],
		'en': [
			{
				'label': 'aws',
				'url': '/en/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 52,
				'height': 32
			},
			{
				'label': 'derbystar',
				'url': 'https://redirect.bundesliga.com/derbystar',
				external: true,
				ext: 'svg',
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_bar_int',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'https://www.easports.com/uk/fifa?change_culture=1',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': 'https://uk.topps.com/index.php/sport/bundesliga-uk.html',
				external: true,
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		],
		'fr': [
			{
				'label': 'aws',
				'url': '/en/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 52,
				'height': 32
			},
			{
				'label': 'derbystar',
				'url': 'https://redirect.bundesliga.com/derbystar',
				external: true,
				ext: 'svg',
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_bar_int',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'https://www.easports.com/uk/fifa?change_culture=1',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': 'https://uk.topps.com/index.php/sport/bundesliga-uk.html',
				external: true,
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		],
		'jp': [
			{
				'label': 'aws',
				'url': '/en/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 52,
				'height': 32
			},
			{
				'label': 'derbystar',
				'url': 'https://redirect.bundesliga.com/derbystar',
				external: true,
				ext: 'svg',
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_bar_int',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'https://www.easports.com/uk/fifa?change_culture=1',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': 'https://www.topps.com/cards-collectibles.html?property=11221&p=1',
				external: true,
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		],
		'es': [
			{
				'label': 'aws',
				'url': '/en/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 52,
				'height': 32
			},
			{
				'label': 'derbystar',
				'url': 'https://redirect.bundesliga.com/derbystar',
				external: true,
				ext: 'svg',
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_bar_int',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'https://www.easports.com/uk/fifa?change_culture=1',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': 'https://es.topps.com/football/bundesliga-es.html',
				external: true,
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		],
		'ar': [
			{
				'label': 'aws',
				'url': '/en/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 52,
				'height': 32
			},
			{
				'label': 'derbystar',
				'url': 'https://redirect.bundesliga.com/derbystar',
				external: true,
				ext: 'svg',
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_bar_int',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'https://www.easports.com/uk/fifa?change_culture=1',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': 'https://uk.topps.com/index.php/sport/bundesliga-uk.html',
				external: true,
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		],
		'pt': [
			{
				'label': 'aws',
				'url': '/en/bundesliga/route-info/provider/aws',
				external: false,
				ext: 'svg',
				'width': 52,
				'height': 32
			},
			{
				'label': 'derbystar',
				'url': 'https://redirect.bundesliga.com/derbystar',
				external: true,
				ext: 'svg',
				'width': 120,
				'height': 30
			},
			{
				'label': 'milka',
				'url': 'https://redirect.bundesliga.com/milka_bar_int',
				external: true,
				ext: 'svg',
				'width': 70,
				'height': 34
			},
			{
				'label': 'ea',
				'url': 'https://www.easports.com/uk/fifa?change_culture=1',
				external: true,
				ext: 'svg',
				'width': 40,
				'height': 40
			},
			{
				'label': 'sorare',
				'url': 'https://sorare.com/bundesliga',
				external: true,
				ext: 'svg',
				'width': 117,
				'height': 19
			},
			{
				'label': 'topps',
				'url': 'https://uk.topps.com/index.php/sport/bundesliga-uk.html',
				external: true,
				ext: 'svg',
				'width': 62,
				'height': 34
			}
		]
	},

	footerSocials: {
		'de': [
			{
				'label': 'facebook',
				'url': '',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'x',
				'url': 'https://x.com/bundesliga_de',
				ext: 'svg',
				external: true,
				'width': 24,
				'height': 24
			},
			{
				'label': 'youtube',
				'url': '',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'linkedin',
				'url': 'https://www.linkedin.com/company/dfl-official/',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'instagram',
				'url': '',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'tiktok',
				'url': '',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			}
		],
		'en': [
			{
				'label': 'facebook',
				'url': 'https://www.facebook.com/BundesligaOfficial/',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'x',
				'url': 'https://x.com/bundesliga_EN',
				ext: 'svg',
				external: true,
				'width': 24,
				'height': 24
			},
			{
				'label': 'youtube',
				'url': 'https://www.youtube.com/@bundesliga/videos',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'linkedin',
				'url': 'https://www.linkedin.com/company/dfl-official/',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'instagram',
				'url': 'https://www.instagram.com/bundesliga/',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'tiktok',
				'url': 'https://www.tiktok.com/@bundesliga?',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			}
		],
		'fr': [
			{
				'label': 'facebook',
				'url': '',
				external: false,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'x',
				'url': '',
				ext: 'svg',
				external: true,
				'width': 24,
				'height': 24
			},
			{
				'label': 'youtube',
				'url': '',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'linkedin',
				'url': 'https://www.linkedin.com/company/dfl-official/',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'instagram',
				'url': '',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'tiktok',
				'url': '',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			}
		],
		'es': [
			{
				'label': 'facebook',
				'url': '',
				external: false,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'x',
				'url': '',
				ext: 'svg',
				external: true,
				'width': 24,
				'height': 24
			},
			{
				'label': 'youtube',
				'url': '',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'linkedin',
				'url': 'https://www.linkedin.com/company/dfl-official/',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'instagram',
				'url': '',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'tiktok',
				'url': '',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			}
		],
		'ar': [
			{
				'label': 'facebook',
				'url': 'https://www.facebook.com/BundesligaOfficial/',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'x',
				'url': 'https://x.com/bundesliga_EN',
				ext: 'svg',
				external: true,
				'width': 24,
				'height': 24
			},
			{
				'label': 'youtube',
				'url': 'https://www.youtube.com/@bundesliga/videos',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'linkedin',
				'url': 'https://www.linkedin.com/company/dfl-official/',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'instagram',
				'url': 'https://www.instagram.com/bundesliga/',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'tiktok',
				'url': 'https://www.tiktok.com/@bundesliga?',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			}
		],
		'pt': [
			{
				'label': 'facebook',
				'url': 'https://www.facebook.com/BundesligaOfficial/',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'x',
				'url': 'https://x.com/bundesliga_EN',
				ext: 'svg',
				external: true,
				'width': 24,
				'height': 24
			},
			{
				'label': 'youtube',
				'url': 'https://www.youtube.com/@bundesliga/videos',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'linkedin',
				'url': 'https://www.linkedin.com/company/dfl-official/',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'instagram',
				'url': 'https://www.instagram.com/bundesliga/',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			},
			{
				'label': 'tiktok',
				'url': 'https://www.tiktok.com/@bundesliga?',
				external: true,
				ext: 'svg',
				'width': 24,
				'height': 24
			}
		]
	},
	globalMatchbar: {
		ignoreUrlSlugs: ['', 'info', 'account', 'live-blog-all-games-fixtures-results-scores-conference', 'liveticker-ergebnisse-tabelle', 'futbol-en-vivo-alemania-partidos-resultados-goles']
	}
};
